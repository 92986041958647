import React from "react";
import LatestProject from "../04. Work/projects/project/Project";
import unitConverterImage from "./assets/images/TC1W0L0T.webp";
import "../04. Work/Work.css";

const LatestApp = () => {
  return (
    <div className="work-section">
      <div className="work-section-content">
        <h1>Latest App</h1>
        <p className="latest-app">
          Here’s one of my latest creations published on the App Store, feel
          free to check it out{" "}
          <a href="https://apps.apple.com/us/app/ultimate-unit-converter-pro/id6463096286">
            here
          </a>
          .
        </p>
        <LatestProject
          id="latest-app"
          image={unitConverterImage}
          title="Unit Converter"
          repository="unit-converter"
          demoLink="https://apps.apple.com/us/app/ultimate-unit-converter-pro/id6463096286"
        />
      </div>
    </div>
  );
};

export default LatestApp;
