import { differenceInYears } from "date-fns";
import AboutMeImage from "./assets/images/GXWJ1ZXL.webp";
import React from "react";
import "./About.css";

const About = () => {
  const birthDate = new Date("01/23/2001");

  const getAge = () => {
    const now = new Date();
    const years = differenceInYears(now, birthDate);
    return years;
  };
  return (
    <div className="about-section">
      <div className="about-section-content">
        <img src={AboutMeImage} alt="GXWJ1ZXL" />
        <div className="about-text">
          <h1>Hi, I’m Talal. Nice to meet you.</h1>
          <p>
            I'm a highly aspiring computer science student with a passion for
            building things that live on the internet. I started learning how to
            code right after I graduated from high school, and since then,
            coding has become an engaging hobby for me which helped me develop
            and design countless software projects.
          </p>
          <p>
            I am {getAge()} years old and this portfolio reflects my journey and
            growth as a developer.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
