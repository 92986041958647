import { formatDistanceToNow } from "date-fns";
import React from "react";
import "./Footer.css";
import {
  faGithub,
  faInstagram,
  faItchIo,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import IconLink from "./components/icon-link/IconLink";

const Footer = () => {
  const lastUpdatedDate = new Date("10/19/2024");

  const getLastUpdatedText = () => {
    const distance = formatDistanceToNow(lastUpdatedDate, { addSuffix: true });
    return `Last updated ${distance}`;
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className="footer-section">
      <div className="footer-section-content">
        <div className="footer-text">
          <h1>Living, learning, & leveling up one day at a time.</h1>
          <div className="icon-links">
            <IconLink link="https://github.com/talalzeini" icon={faGithub} />
            <IconLink link="https://talalzeini.itch.io" icon={faItchIo} />
            <IconLink
              link="https://linkedin.com/in/talalzeini"
              icon={faLinkedinIn}
            />
            <IconLink
              link="https://instagram.com/talalzeini"
              icon={faInstagram}
            />
            <IconLink link="mailto:talalzeini@icloud.com" icon={faEnvelope} />
          </div>

          <a href="mailto:talalzeini@icloud.com">Contact</a>
          <a href="https://bit.ly/444uVQ4" target="_blank" rel="noreferrer">
            Donate
          </a>
          <p>{getLastUpdatedText()}</p>
          <p>© {getCurrentYear()} Talal El Zeini</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
