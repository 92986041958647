import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"; // Importing the GitHub and LinkedIn icons
import "./NavigationBar.css";

const NavigationBar = () => {
  return (
    <nav className="navigation-bar">
      <div className="navigation-bar-name">
        <a href="https://talalzeini.com">Talal El Zeini</a>
      </div>
      <div className="navigation-bar-icons">
        <a
          href="https://github.com/talalzeini"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
        <a
          href="https://linkedin.com/in/talalzeini"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
      </div>
    </nav>
  );
};

export default NavigationBar;
