import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./components/00. Home/Home";
import "./App.css";

// Social
const LinkedInLink = "https://www.linkedin.com/in/talalzeini";
const InstagramLink = "https://www.instagram.com/talalzeini";
const MediumLink = "https://medium.com/@techwithtalal";
const HashnodeLink = "https://hashnode.com/@techwithtalal";

// Development
const GitHubLink = "https://www.github.com/talalzeini";
const StackOverflowLink =
  "https://stackoverflow.com/users/17853635/talal-el-zeini";

// iOS Apps
const TipCalculatorLink =
  "https://apps.apple.com/us/app/ultimate-tip-calculator-pro/id6504262579";
const UnitConverterLink =
  "https://apps.apple.com/us/app/ultimate-unit-converter-pro/id6463096286";

// Other
const GamesLink = "https://talalzeini.itch.io";
const LinksTreeLink = "https://talalzeini.github.io/links-tree/";
const StudentResearchLeagueLink =
  "https://www.westvalley.edu/offices/marketing/stories/winner-of-2021-AMATYC-Student-Research-League-Grand-Prize.html";
const EtsyShopLink = "https://www.etsy.com/shop/TechWithTalal";

function Redirect({ link }) {
  React.useEffect(() => {
    console.log(`Redirecting to: ${link}`);
    window.location.replace(link);
  }, [link]);

  return null; // Or you can return a loading spinner or message
}

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Social */}
        <Route path="/linkedin" element={<Redirect link={LinkedInLink} />} />
        <Route path="/instagram" element={<Redirect link={InstagramLink} />} />
        <Route path="/medium" element={<Redirect link={MediumLink} />} />
        <Route path="/hashnode" element={<Redirect link={HashnodeLink} />} />

        {/* Development */}
        <Route path="/github" element={<Redirect link={GitHubLink} />} />
        <Route
          path="/stackoverflow"
          element={<Redirect link={StackOverflowLink} />}
        />

        {/* iOS Apps */}
        <Route
          path="/unit-converter"
          element={<Redirect link={UnitConverterLink} />}
        />
        <Route
          path="/tip-calculator"
          element={<Redirect link={TipCalculatorLink} />}
        />
        {/* Other */}
        <Route path="/links" element={<Redirect link={LinksTreeLink} />} />
        <Route path="/games" element={<Redirect link={GamesLink} />} />
        <Route
          path="/champion"
          element={<Redirect link={StudentResearchLeagueLink} />}
        />
        <Route path="/etsy" element={<Redirect link={EtsyShopLink} />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
// Font Awesome
// npm install date-fns
// npm i  @fortawesome/react-fontawesome
// npm i @fortawesome/free-solid-svg-icons
// npm i @fortawesome/free-regular-svg-icons
// npm i @fortawesome/free-brands-svg-icons
// npm i @fortawesome/fontawesome-svg-core
// npm install bootstrap
// npm i react-router-dom
// npm i gh-pages --save-dev
// npm install react-bootstrap bootstrap
// npm i react-appstore-button
// npm i react-typewriter-effect
