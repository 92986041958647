import React from "react";
import "./Work.css";
import Projects from "./projects/Projects";

const Work = () => {
  return (
    <div className="work-section">
      <div className="work-section-content">
        <h1>Other Work</h1>
        <p>
          Here are a few projects I've worked on. Want to see more?{" "}
          <a href="mailto:talalzeini@icloud.com">Email me</a>.
        </p>
      </div>
      <Projects />
    </div>
  );
};

export default Work;
