import React, { useEffect, useState } from "react";
import "./Landing.css";
import NavigationBar from "./components/nagivation-bar/NavigationBar";
import resumePDF from "./assets/files/talal-elzeini-resume.pdf";

const Landing = () => {
  const [helloText, setHelloText] = useState("Say Hello");
  const [jobTitle, setJobTitle] = useState("Software Engineer");

  const toggleEmail = () => {
    window.location = "mailto:talalzeini@icloud.com";
  };

  const downloadResume = () => {
    const link = document.createElement("a");
    link.href = resumePDF;
    link.setAttribute("download", "talal-elzeini-resume.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getRandomHelloText = () => {
    const helloOptions = ["Say Hello", "Contact Me", "Get in Touch"];
    return helloOptions[Math.floor(Math.random() * helloOptions.length)];
  };

  const getRandomJobTitle = () => {
    const jobTitles = [
      "iOS Software Engineer",
      "Software Engineer",
      "iOS Developer",
    ];
    return jobTitles[Math.floor(Math.random() * jobTitles.length)];
  };

  useEffect(() => {
    setHelloText(getRandomHelloText());
    setJobTitle(getRandomJobTitle());
  }, []);

  return (
    <>
      <div className="landing-section">
        <NavigationBar />
        <div className="landing-section-content">
          <h1>{jobTitle}</h1>
          <p>
            I design and code beautifully simple things, and I love what I do.
          </p>
          <div className="landing-buttons">
            <button onClick={downloadResume}>Resume</button>
            <button onClick={toggleEmail}>{helloText}</button>
          </div>
          <div className="scroll-down-animation"></div>
        </div>
      </div>
    </>
  );
};

export default Landing;
