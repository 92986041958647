import React from "react";
import "./Project.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const Project = ({ image, title, repository, demoLink }) => {
  const githubLink = "https://github.com/talalzeini/" + repository;

  if (!demoLink) {
    demoLink = "https://talalzeini.github.io/" + repository;
  }

  if (repository.includes("game")) {
    demoLink = "https://talalzeini.itch.io/" + repository;
  }

  return (
    <>
      <div className="project-section">
        <div className="project-section-content">
          <img src={image} alt="Screenshot of a project" />
          <div className="project-overlay">
            <h2>{title}</h2>
            <div className="project-icons">
              <a href={githubLink} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a href={demoLink}>
                <FontAwesomeIcon
                  icon={faLink}
                  target="_blank"
                  rel="noreferrer"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
