import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./IconLink.css";

const IconLink = ({ link, icon }) => {
  return (
    <>
      <a href={link} target="_blank" rel="noreferrer">
        <FontAwesomeIcon
          icon={icon}
          className="icon"
          target="_blank"
          rel="noreferrer"
        />
      </a>
    </>
  );
};

export default IconLink;
