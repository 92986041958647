import React from "react";
import Landing from "../01. Landing/Landing";
import About from "../02. About/About";
import LatestApp from "../03. Latest App/LatestApp";
import Work from "../04. Work/Work";
import Footer from "../05. Footer/Footer";

const Home = () => {
  return (
    <>
      <Landing />
      <About />
      <LatestApp />
      <Work />
      <Footer />
    </>
  );
};

export default Home;
