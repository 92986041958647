import React from "react";
import cryptoTrackerImage from "../assets/images/websites/6CXFXIBN.webp";
import quizGeneratorImage from "../assets/images/websites/CHBTRH02.webp";
import sortingVisualizerImage from "../assets/images/websites/JJGKIDWA.webp";
import asteroidsGameImage from "../assets/images/games/NNLNMG2M.webp";
import hexagonsGameImage from "../assets/images/games/5RQUN0TI.webp";
import blocksGameImage from "../assets/images/games/KDOCQVN3.webp";
import Project from "./project/Project";
import "./Projects.css";

const Projects = () => {
  return (
    <>
      <div className="projects-section">
        <Project
          image={sortingVisualizerImage}
          title="Sorting Visualizer"
          repository="sorting-visualizer"
        />
        <Project
          image={cryptoTrackerImage}
          title="Crypto Tracker"
          repository="crypto-tracker"
        />
        <Project
          image={quizGeneratorImage}
          title="Quiz Generator"
          repository="quiz-generator"
        />
        <Project
          image={asteroidsGameImage}
          title="Asteroids Game"
          repository="asteroids-game"
        />
        <Project
          image={blocksGameImage}
          title="Blocks Game"
          repository="blocks-game"
          type="game"
        />
        <Project
          image={hexagonsGameImage}
          title="Hexagons Game"
          repository="hexagons-game"
        />
      </div>
    </>
  );
};

export default Projects;
